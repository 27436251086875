// stores/settings.ts
import { defineStore } from 'pinia';

export const useSettingsData = defineStore('settings', {
  state: () => ({
    design: null as any | null,
  }),
  actions: {
    async getDesign() {
      const settingsStore = useSettingsStore();
      if (!this.design) {
        try {
            // const response = await settingsStore.getDesign();
            const response = await useFetchAuth(`/api/v1/back/settings/design/get`, {
                method: 'POST'
            });
            this.design = response;
        } catch (error) {
          console.error('Error fetching design settings:', error);
        }
      }
    },
  },
});